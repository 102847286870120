<template>
  <div>
    <b-carousel
      v-if="true"
      id="carousel-banner"
      controls
      indicators
    >
      <b-carousel-slide>
        <template #img>
          <b-card
            no-body
            class="overflow-hidden cardBanner"
          >
            <b-row no-gutters>
              <img
                :src="getImageUrl"
                alt=""
                class="rounded-0 imgBanner"
              >
              <b-col md="8">
                <b-card-body>
                  <div
                    class="cardTextBanner"
                    v-html="flash.text_infos"
                  />
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-carousel-slide>

    </b-carousel>

    <div class="editorContainer">
      <b-card class="mb-1">
        <b-card class="w-50">
          <div style="display: none">
            <b-form-file
              ref="flashfile"
              accept=".png,.jpg,.jpeg"
              name="piece_jointe"
              hidden
              @change="formatBase64File($event)"
            />
          </div>
          <b-form-group
            label="Libelle :"
            label-for="libelle"
            class="w-50"
          >
            <validation-provider
              #default="{ errors }"
              name="Libelle"
            >
              <b-form-input
                id="libelle"
                v-model="flash.libelle"
                name="libelle"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            class="mr-2"
            @click="uploadFile()"
          >
            Charger une image
          </b-button>
        </b-card>
        <vue-editor
          v-model="text_infos"
          :editor-toolbar="toolbar"
          @keyup="getKey"
        />
        <p>Total Remaining: <span :class="{'text-danger': generateErr }">{{ totalRemainCount }}</span></p>

        <div class="mt-2">
          <b-row
            class="justify-content-end"
            md="auto"
          >
            <b-col
              cols="auto"
              class="float-right"
            >
              <b-button
                @click="annuler()"
              >
                Annuler
              </b-button>
            </b-col>
            <b-col
              cols="auto"
              class="float-right"
            >
              <b-button
                variant="primary"
                align-h="end"
                @click="saveInfoFlash()"
              >
                Enregister
              </b-button>
            </b-col>
          </b-row>

        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-quill-editor'

import {
  BModal, BForm, BFormInput, BFormGroup, BFormTextarea, BFormFile, BImg,
  BCard, BButton, BCol, BRow, BBadge, BFormCheckbox, BCardBody, BCardText,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import {
  ValidationProvider, ValidationObserver, localize, validate,
} from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import CustomFilter from './competences-fonctions/customFilter.vue'

localize('fr', fr)
export default {
  components: {
    VueEditor,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BBadge,
    BCol,
    BRow,
    BFormTextarea,
    BFormFile,
    BImg,
    VueGoodTable,
    CustomFilter,
    ValidationProvider,
    BCardBody,
    // ModalUpdate,
  },
  data() {
    return {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        ['link'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

        [{ size: ['small', 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ['clean'], // remove formatting button
      ],
      imageURL: null,
      inputFile: null,
      flash: {
        text_infos: '',
        piece_jointe: '',
        libelle: '',
        actif: true,
      },
      text_infos: '',
      textMarking: '',
      showModalImage: false,
      dataModalImage: null,
      fromRow: false,
      rowId: '',
      defaulLibelle: '',
      defaultTextInfos: '',
      piece_jointe: null,
      modalShow: false,
      modalTitle: '',
      checked: false,
      limitmaxCount: 600,
      totalRemainCount: 600,
      generateErr: '',
    }
  },
  computed: {
    getImageUrl() {
      return this.imageURL || '#'
    },
    markedInput() {
      return this.$Marked(this.textMarking)
    },
    isChecked() {
      return this.checked ? 'actif' : 'inactif'
    },
  },
  watch: {
    text_infos(newVal, oldVal) {
      this.liveCountDown(newVal, oldVal)
    },
  },
  methods: {
    liveCountDown(newVal, oldVal) {
      this.totalRemainCount = this.limitmaxCount - newVal.replace(/<\/?[^>]+(>|$)/g, '').length
      this.generateErr = this.totalRemainCount < 0
      if (this.totalRemainCount < 0 && newVal.replace(/<\/?[^>]+(>|$)/g, '') > oldVal.replace(/<\/?[^>]+(>|$)/g, '')) {
        this.flash.text_infos = oldVal
        this.$swal({
          title: 'Nombre de caractères maximum atteint',
          text: 'Vous avez atteint la limite de caractères, merci de réduire votre texte et vérifier l\'aperçu de votre message flash',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.flash.text_infos = newVal
      }
    },
    uploadFile() {
      this.$refs.flashfile.$refs.input.click()
    },
    formatBase64File(event) {
      // eslint-disable-next-line prefer-destructuring
      this.inputFile = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.inputFile)
      reader.onload = () => {
        this.imageURL = reader.result
        this.flash.piece_jointe = reader.result
      }
    },
    saveInfoFlash() {
      const newFlash = this.flash
      this.$store.dispatch('flashinfo/createFlashInfo', newFlash).then(res => {
        this.$store.dispatch('flashinfo/getAllFlashinfos').then(response => {
          this.imageURL = null
          this.$router.push({ name: 'admin-flashinfo' })
        })
      }).catch(error => {
        // this.alertModal({ title: 'Erreur', text: error.message, icon: 'error' })
      })
    },
    annuler() {
      this.$router.push({ name: 'admin-flashinfo' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#detailIcon {
  cursor: pointer
}
.editorContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.editorContainer b-card {
  justify-content: space-evenly;
}

.cardBanner {
  max-width : 100%;
  max-height: 20vh;
  min-height: 15vh;
}
.imgBanner {
 object-fit: contain;
   max-width: 100%;
   max-height: 20vh;
   width: auto;
   height: auto;;
}
.cardTextBanner {
  margin-left: 10px;
}

.carousel-control-prev-icon {
  left: -25px;
  position: absolute;
}

.carousel-control-next-icon {
  right: -25px;
  position: absolute;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px !important;
  width: 100px !important;
  filter: invert(7%) sepia(84%) saturate(0%) hue-rotate(250deg) brightness(60%) contrast(99%);
}

.carousel-control-next {
  display: flex !important;
  justify-content: right !important;
}

.carousel-control-prev {
  display: flex !important;
  justify-content: left !important;
}
</style>
